
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useState } from "react";
import { Box, Flex } from "rebass/styled-components";
import { getApolloClient, addApolloState } from "@faustwp/core";
import Post from "../components/Post";
import FindSitter from "../components/promotion/FindSitter";
import SubscribeBlock from "../components/promotion/SubscribeBlock";
import InfoBlock from "../components/promotion/InfoBlock";
import MobileApp from "../components/promotion/MobileApp";
import SecondaryPosts from "../components/sections/SecondaryPosts";
import Pagination from "../components/sections/Pagination";
import Layout from "../components/Layout";
import PopUp from "../components/common/PopUp";
import SignUp from "../components/SignUp";
import Loader from "../components/Loader";
import useIsMobile from "../hooks/useIsMobile";
import { InferredMedia, InferredPost } from "../types";
import { useGetMediaDataQuery, useGetHomePageDataQuery, GetHomePageDataDocument, GetMenusDocument, } from "../generated/graphql";
import styles from "../styles/frontPageStyle";
import theme from "../styles/theme";
export const getPostWithImg = (posts: InferredPost[], media: InferredMedia[]) => {
    const postsWithImg = posts.map((post) => {
        if (!post.featuredImage) {
            const postMedia = media.filter((media) => media.parentId === post.id);
            const imgNode = { node: postMedia[postMedia.length - 1] };
            const obj = { ...post, featuredImage: imgNode };
            return obj;
        }
        else
            return post;
    });
    return postsWithImg;
};
export default function Component() {
    const isMobile = useIsMobile();
    const [showSignUp, setShowSignUp] = useState(false);
    // Queries
    const { data: pageData, loading } = useGetHomePageDataQuery();
    const getImages = (postIds: string[]) => {
        const { data: mediaData, loading } = useGetMediaDataQuery({
            variables: { postIds: postIds || [""] },
        });
        const newMedia: InferredMedia[] = mediaData?.mediaItems?.nodes || [];
        return newMedia;
    };
    // Posts
    const posts: InferredPost[] = pageData?.posts?.nodes || [];
    const media = posts && getImages(posts.map((post) => post.id));
    const postWithImg = getPostWithImg(posts, media);
    const [lastPost, ...recentPosts] = postWithImg;
    // const [lastPost, firstSidePost, secondSidePost, ...recentPosts] = postWithImg;
    const totalPosts: NonNullable<number> = pageData?.posts?.pageInfo.offsetPagination?.total || 0;
    // PopUp
    // useEffect(() => {
    //   const timer = setTimeout(() => {
    //     setShowSignUp(true);
    //   }, 5000);
    //   return () => clearTimeout(timer);
    // }, []);
    // Rendering
    const renderPosts = () => {
        return [...Array(7)].map((_, i) => {
            let comp = <></>;
            if (isMobile && i < 3) {
                comp = <Post key={i} postData={recentPosts[i]} postType="scndPost"/>;
            }
            else if ((isMobile && i === 3) || (!isMobile && i === 0)) {
                comp = <InfoBlock key={i} type={isMobile ? "column" : "row"}/>;
            }
            else {
                comp = (<Post key={i} postData={recentPosts[i - 1]} postType="scndPost"/>);
            }
            return comp;
        });
    };
    return (<>
      <Layout>
        <Flex sx={{ flexDirection: "column", gap: "24px" }}>
          <Flex sx={{ ...theme.colToRow, gap: "32px" }}>
            <Box sx={styles.mainPost}>
              {lastPost && <Post postData={lastPost} postType="mainPost"/>}
            </Box>

            <Flex sx={{
            flexDirection: "column",
            gap: "16px",
            ...styles.sidePost,
        }}>
              <FindSitter origin="front-page"/>

              {/* <Post
          postData={firstSidePost}
          postType={isMobile ? "mainPost" : "sidePost"}
        />

        <Post
          postData={secondSidePost}
          postType={isMobile ? "mainPost" : "sidePost"}
        /> */}

              <SubscribeBlock type="column"/>
            </Flex>
          </Flex>

          <SecondaryPosts>{renderPosts()}</SecondaryPosts>

          <Pagination basePath={"/"} pageSelected={1} totalPosts={totalPosts} maxNumPages={isMobile ? 6 : 8}/>

          <MobileApp isMobile={isMobile}/>

          <SubscribeBlock type={isMobile ? "column" : "row"}/>
        </Flex>
      </Layout>
      <PopUp show={showSignUp}>
        <SignUp isMobile={isMobile} onClose={() => setShowSignUp(false)}/>
      </PopUp>
    </>);
}
const getServerSideProps = async () => {
    const apolloClient = getApolloClient();
    await Promise.all([
        apolloClient.query({
            query: GetHomePageDataDocument,
        }),
        apolloClient.query({ query: GetMenusDocument }),
    ]);
    return addApolloState(apolloClient, {
        props: {},
    });
};

    async function __Next_Translate__getServerSideProps__19598daac49__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__19598daac49__ as getServerSideProps }
  